import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks shortTwo">
                                <button onClick={() => scrollTo("enduser")}>User Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>User Side Features for Property Buy/Rent/Sell Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Registration & Profile Management</h3>
                                    <ul>
                                        <li><span><b>User Registration:</b> Easy registration process for creating a user account.</span></li>
                                        <li><span><b>Profile Creation:</b> Ability to create and manage a detailed user profile with personal and contact information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Search & Filters</h3>
                                    <ul>
                                        <li><span><b>Advanced Search:</b> Robust search functionality with filters for location, property type, price range, and amenities.</span></li>
                                        <li><span><b>Map Integration:</b> Visual representation of properties on a map for better location understanding.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Listings</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Listings:</b> Detailed property listings with high-quality images, descriptions, and key details.</span></li>
                                        <li><span><b>Virtual Tours:</b> Integration of virtual tours or 360-degree views for select properties.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Favorites & Shortlists</h3>
                                    <ul>
                                        <li><span><b>Favorites List:</b> Ability to save favorite properties for easy reference.</span></li>
                                        <li><span><b>Shortlist Feature:</b> Create shortlists for properties under consideration.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Contacting Sellers or Agents</h3>
                                    <ul>
                                        <li><span><b>Inquiry Forms:</b> Easily contact sellers or agents through inquiry forms.</span></li>
                                        <li><span><b>Direct Messaging:</b> Option for direct messaging or contact details for immediate communication.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Details & History</h3>
                                    <ul>
                                        <li><span><b>Property History:</b> Access to property history, including previous transactions and ownership details.</span></li>
                                        <li><span><b>Comprehensive Details:</b> In-depth information on property specifications, dimensions, and features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling</h3>
                                    <ul>
                                        <li><span><b>Book Viewings:</b> Schedule appointments to view properties in person.</span></li>
                                        <li><span><b>Virtual Appointments:</b> Request virtual viewings or online consultations with sellers or agents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Price Alerts & Notifications</h3>
                                    <ul>
                                        <li><span><b>Price Change Alerts:</b> Receive notifications for price changes on favorited or shortlisted properties.</span></li>
                                        <li><span><b>New Listings Notifications:</b> Alerts for new properties that match specified criteria.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Negotiation Tools</h3>
                                    <ul>
                                        <li><span><b>Offer Submission:</b> Ability to submit offers or negotiate prices through the platform.</span></li>
                                        <li><span><b>Counteroffer Feature:</b> Receive and respond to counteroffers from sellers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Legal & Documentation Assistance</h3>
                                    <ul>
                                        <li><span><b>Legal Guidance:</b> Access to information on legal processes and documentation requirements.</span></li>
                                        <li><span><b>Documentation Checklist:</b> Checklist for necessary documents during property transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Reviews & Ratings</h3>
                                    <ul>
                                        <li><span><b>User Reviews:</b> Read reviews from other users about properties, neighborhoods, or agents.</span></li>
                                        <li><span><b>Rating System:</b> Rate and review properties based on personal experiences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Market Trends & Insights</h3>
                                    <ul>
                                        <li><span><b>Real Estate Trends:</b> Access to market trends and insights for informed decision-making.</span></li>
                                        <li><span><b>Price Comparisons:</b> Tools for comparing property prices in different locations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Notification Preferences</h3>
                                    <ul>
                                        <li><span><b>Customized Notifications:</b> Set preferences for property alerts, news, and updates.</span></li>
                                        <li><span><b>Newsletter Subscriptions:</b> Subscribe to newsletters for real estate tips and market updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access the platform through a user-friendly mobile app.</span></li>
                                        <li><span><b>Push Notifications:</b> Receive push notifications for new listings, messages, and appointment reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Interactive Maps & Neighborhood Information</h3>
                                    <ul>
                                        <li><span><b>Neighborhood Maps:</b> Explore neighborhood details, amenities, and nearby facilities.</span></li>
                                        <li><span><b>School & Hospital Information:</b> Access information on schools, hospitals, and other local services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Storage</h3>
                                    <ul>
                                        <li><span><b>Secure Document Upload:</b> Upload and store important documents securely, such as contracts and agreements.</span></li>
                                        <li><span><b>Transaction History:</b> Access a history of transactions and property-related documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Customer Support</h3>
                                    <ul>
                                        <li><span><b>Feedback Forms:</b> Provide feedback on user experience, platform features, and support.</span></li>
                                        <li><span><b>Customer Support Channels:</b> Access to customer support through chat, email, or helpline.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Property Buy/Rent/Sell Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management</h3>
                                    <ul>
                                        <li><span><b>User Profiles:</b> View and manage user profiles, including contact information and activity history.</span></li>
                                        <li><span><b>User Roles & Permissions:</b> Assign roles and permissions for staff members and ensure secure access.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Management</h3>
                                    <ul>
                                        <li><span><b>Property Listings:</b> Add, edit, and manage property listings with detailed information.</span></li>
                                        <li><span><b>Listing Approval:</b> Review and approve new property listings to ensure quality and accuracy.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transaction Monitoring</h3>
                                    <ul>
                                        <li><span><b>Transaction History:</b> Access a comprehensive history of property transactions on the platform.</span></li>
                                        <li><span><b>Financial Reports:</b> Generate financial reports related to transactions, commissions, and fees.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Agent & Seller Management</h3>
                                    <ul>
                                        <li><span><b>Agent Profiles:</b> Manage profiles of real estate agents and sellers.</span></li>
                                        <li><span><b>Performance Metrics:</b> Track the performance of agents and sellers based on transactions and customer feedback.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Hub</h3>
                                    <ul>
                                        <li><span><b>Announcements & Updates:</b> Broadcast announcements, updates, and policy changes to users.</span></li>
                                        <li><span><b>Messaging System:</b> Monitor and moderate communications between users and agents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment & Viewing Management</h3>
                                    <ul>
                                        <li><span><b>Appointment Scheduling:</b> View and manage property viewing appointments.</span></li>
                                        <li><span><b>Appointment Confirmation:</b> Confirm and coordinate appointments between buyers and sellers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Negotiation & Offer Management</h3>
                                    <ul>
                                        <li><span><b>Offer Review:</b> Review and moderate offers submitted by users.</span></li>
                                        <li><span><b>Counteroffer Management:</b> Monitor and facilitate counteroffers during negotiations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Legal & Documentation Assistance</h3>
                                    <ul>
                                        <li><span><b>Documentation Check:</b> Ensure users are provided with accurate information on required documentation.</span></li>
                                        <li><span><b>Legal Compliance:</b> Monitor legal compliance of property transactions and documentation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Price Monitoring & Analytics</h3>
                                    <ul>
                                        <li><span><b>Pricing Tools:</b> Access tools for monitoring property prices, market trends, and price changes.</span></li>
                                        <li><span><b>Competitor Analysis:</b> Analyze pricing strategies of competitors and similar listings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Management</h3>
                                    <ul>
                                        <li><span><b>Support Ticket System:</b> Manage and respond to user support tickets.</span></li>
                                        <li><span><b>FAQ & Knowledge Base:</b> Maintain a comprehensive FAQ section and knowledge base for user assistance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Marketing & Promotion</h3>
                                    <ul>
                                        <li><span><b>Promotional Campaigns:</b> Plan and execute marketing campaigns for featured listings.</span></li>
                                        <li><span><b>Promo Codes:</b> Implement promotional codes and discounts for specific properties.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Analytics & Reporting</h3>
                                    <ul>
                                        <li><span><b>User Activity Reports:</b> Generate reports on user activity, engagement, and preferences.</span></li>
                                        <li><span><b>Property Performance Reports:</b> Track the performance of listings based on views, inquiries, and transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Verification</h3>
                                    <ul>
                                        <li><span><b>Document Submission Review:</b> Verify and approve documents submitted by users for property transactions.</span></li>
                                        <li><span><b>Secure Document Storage:</b> Ensure secure storage of sensitive documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform Customization</h3>
                                    <ul>
                                        <li><span><b>Configuration Settings:</b> Customize platform settings, including search filters and user interface elements.</span></li>
                                        <li><span><b>Branding & Themes:</b> Implement branding elements and themes for a consistent user experience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Compliance</h3>
                                    <ul>
                                        <li><span><b>Data Encryption:</b> Implement robust data encryption protocols for user and transactional data.</span></li>
                                        <li><span><b>Regulatory Compliance Checks:</b> Ensure compliance with real estate regulations and standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Management</h3>
                                    <ul>
                                        <li><span><b>Third-Party Integrations:</b> Manage integrations with external vendors for services like legal assistance or property valuation.</span></li>
                                        <li><span><b>Performance Assessment:</b> Evaluate the performance of third-party service providers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Improvement</h3>
                                    <ul>
                                        <li><span><b>User Feedback Analysis:</b> Analyze user feedback to identify areas for improvement.</span></li>
                                        <li><span><b>Feature Enhancement Planning:</b> Plan updates and enhancements based on user suggestions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile App Management</h3>
                                    <ul>
                                        <li><span><b>App Updates & Maintenance:</b> Oversee updates and maintenance of the mobile app.</span></li>
                                        <li><span><b>Push Notification Management:</b> Configure and send push notifications to users.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;