import React from 'react';
import { LuLayoutDashboard, LuFileKey2, LuTimerReset } from "react-icons/lu";
import { TbChartHistogram, TbFilterSearch, TbLockAccess, TbChartInfographic, TbCheckupList } from "react-icons/tb";
import { FaUsersViewfinder, FaChalkboardUser } from "react-icons/fa6";
import { BsBuildingCheck, BsDatabaseLock } from "react-icons/bs";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiOutlineClipboardDocumentList, HiOutlineArrowPath } from "react-icons/hi2";
import { FiPieChart } from "react-icons/fi";
import { MdOutlineEventRepeat, MdOutlineAppShortcut } from "react-icons/md";
import { BiCustomize, BiSupport } from "react-icons/bi";
import { GoGear } from "react-icons/go";
import { AiOutlineSchedule, AiOutlineApi } from "react-icons/ai";

const FeaturesSection = () => {
    return (
        <>
            <section className="features__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-55">
                                <h2>Key Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="features__wrapper">
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuLayoutDashboard /></i>
                                        </div>
                                        <div>
                                            <h3>Centralized Property Dashboard</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuFileKey2 /></i>
                                        </div>
                                        <div>
                                            <h3>Lease & Tenant Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GoGear /></i>
                                        </div>
                                        <div>
                                            <h3>Maintenance Request System</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbChartHistogram /></i>
                                        </div>
                                        <div>
                                            <h3>Financial Reporting</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><AiOutlineSchedule /></i>
                                        </div>
                                        <div>
                                            <h3>Maintenance Scheduling</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><FaUsersViewfinder /></i>
                                        </div>
                                        <div>
                                            <h3>Vendor Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BsBuildingCheck /></i>
                                        </div>
                                        <div>
                                            <h3>Dynamic Property Marketplace</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbFilterSearch /></i>
                                        </div>
                                        <div>
                                            <h3>Advanced Search & Filters</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><HiOutlineArrowPath /></i>
                                        </div>
                                        <div>
                                            <h3>Transaction Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><HiOutlineSpeakerphone /></i>
                                        </div>
                                        <div>
                                            <h3>Community Announcements</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><FiPieChart /></i>
                                        </div>
                                        <div>
                                            <h3>Shared Expenses Tracking</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineEventRepeat /></i>
                                        </div>
                                        <div>
                                            <h3>Event Planning & Participation</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><AiOutlineApi /></i>
                                        </div>
                                        <div>
                                            <h3>API Integrations</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BiCustomize /></i>
                                        </div>
                                        <div>
                                            <h3>Customization Options</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbLockAccess /></i>
                                        </div>
                                        <div>
                                            <h3>Role-Based Access</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BsDatabaseLock /></i>
                                        </div>
                                        <div>
                                            <h3>Data Encryption</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineAppShortcut /></i>
                                        </div>
                                        <div>
                                            <h3>Mobile Apps</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuTimerReset /></i>
                                        </div>
                                        <div>
                                            <h3>Real-time Analytics</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbChartInfographic /></i>
                                        </div>
                                        <div>
                                            <h3>Predictive Analytics</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbCheckupList /></i>
                                        </div>
                                        <div>
                                            <h3>Compliance Tracking</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><HiOutlineClipboardDocumentList /></i>
                                        </div>
                                        <div>
                                            <h3>Document Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BiSupport /></i>
                                        </div>
                                        <div>
                                            <h3>Help Desk & Support Ticket System</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><FaChalkboardUser /></i>
                                        </div>
                                        <div>
                                            <h3>Training Resources</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturesSection;