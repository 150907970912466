import React from 'react';

const ProcessSection = () => {
    return (
        <>
            <section className="price__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="price__wrapper">
                                <div className="price__item">
                                    <img src="assets/img/home-img1.png" alt="Sjain" />
                                    <h3>Sustain energy throughout your day</h3>
                                    <p>Establish a foundation for your daily operations and communication, allowing you to concentrate on business growth instead of being bogged down by time-consuming tasks.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img2.png" alt="Sjain" />
                                    <h3>Streamline your leasing process</h3>
                                    <p>Shift your entire lead-to-lease cycle to the digital realm, allowing you to prioritize interactions with people rather than paperwork.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img3.png" alt="Sjain" />
                                    <h3>Automate your accounting processes</h3>
                                    <p>Streamline your bookkeeping, collections, and payments through automation, enabling you to concentrate on the critical aspects that require your attention.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img4.png" alt="Sjain" />
                                    <h3>Devote the necessary focus to your reputation</h3>
                                    <p>Draw in residents and acquire new clients without the need to learn website coding.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img5.png" alt="Sjain" />
                                    <h3>Make timely and well-informed decisions</h3>
                                    <p>Centralize visibility for your business, enabling real-time informed decision-making from one location.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessSection;