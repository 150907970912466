import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeadSection from './HeadSection';
import ContentSection from './ContentSection';

const BuildingManagement = () => {
    return (
        <>
            <PageHelmet pageTitle="Building/Society Management System" />
            <Header />
            <HeadSection />
            <ContentSection />
            <Footer />
        </>
    );
};

export default BuildingManagement;