import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks shortTwo">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for Facility Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Dashboard</h3>
                                    <ul>
                                        <li><span><b>Facility Overview:</b> A centralized dashboard providing a snapshot of available facilities, ongoing maintenance, and upcoming events.</span></li>
                                        <li><span><b>Quick Access to Services:</b> Direct links to common services and facilities for easy navigation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Facility Booking & Reservation</h3>
                                    <ul>
                                        <li><span><b>Interactive Calendar:</b> An interactive calendar displaying availability and schedules for different facilities.</span></li>
                                        <li><span><b>Booking Requests:</b> Submit requests for facility bookings, specifying dates, times, and purposes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Service Requests</h3>
                                    <ul>
                                        <li><span><b>Maintenance Requests:</b> Submit maintenance requests for repairs, cleaning, or other facility-related issues.</span></li>
                                        <li><span><b>Feedback & Status Updates:</b> Receive real-time updates on the status of submitted service requests.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Hub</h3>
                                    <ul>
                                        <li><span><b>Announcements & Alerts:</b> Stay informed about facility-related announcements, events, and alerts.</span></li>
                                        <li><span><b>Community Forum:</b> Participate in discussions, share feedback, and communicate with other users.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Facility Usage History</h3>
                                    <ul>
                                        <li><span><b>Usage Records:</b> Access historical data on facility usage, bookings, and events.</span></li>
                                        <li><span><b>Personal Usage History:</b> View personal booking history and participation in community events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access the facility management system through a user-friendly mobile app.</span></li>
                                        <li><span><b>Push Notifications:</b> Receive push notifications for booking confirmations, service updates, and community announcements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Event Participation</h3>
                                    <ul>
                                        <li><span><b>Event Calendar:</b> Explore upcoming community events and facility-related activities.</span></li>
                                        <li><span><b>RSVP & Participation:</b> RSVP for events and track participation in community gatherings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Repository</h3>
                                    <ul>
                                        <li><span><b>Facility Rules & Guidelines:</b> Access to facility-specific rules, guidelines, and user policies.</span></li>
                                        <li><span><b>Event Documents:</b> Download documents related to upcoming events, workshops, or gatherings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Profile Management</h3>
                                    <ul>
                                        <li><span><b>Personal Information:</b> Manage personal details, contact information, and communication preferences.</span></li>
                                        <li><span><b>Privacy Settings:</b> Control the visibility of personal information within the community.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Payment</h3>
                                    <ul>
                                        <li><span><b>Fee Payments:</b> Make payments for facility bookings, event registrations, or community fees.</span></li>
                                        <li><span><b>Payment History:</b> Access a record of past payments and invoices.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Service Feedback:</b> Provide feedback on completed maintenance requests and facility services.</span></li>
                                        <li><span><b>Community Ratings:</b> Participate in community surveys and rate facility services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources</h3>
                                    <ul>
                                        <li><span><b>Guides & FAQs:</b> Access guides, FAQs, and educational resources on using community facilities effectively.</span></li>
                                        <li><span><b>Tips & Reminders:</b> Receive tips and reminders to optimize facility usage and community engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Services Directory</h3>
                                    <ul>
                                        <li><span><b>Local Services:</b> Information on local services, amenities, and businesses in the vicinity.</span></li>
                                        <li><span><b>Recommendations:</b> A platform for users to share and discover recommendations for local services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Social Connectivity</h3>
                                    <ul>
                                        <li><span><b>Community Directory:</b> Access a directory of fellow residents for social connectivity.</span></li>
                                        <li><span><b>Messaging & Chat:</b> Direct messaging and chat features for communication with neighbors.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Emergency Assistance</h3>
                                    <ul>
                                        <li><span><b>Emergency Contacts:</b> Easily accessible emergency contact information and procedures.</span></li>
                                        <li><span><b>Emergency Alerts:</b> Receive immediate alerts and instructions during emergency situations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health & Wellness Tracking</h3>
                                    <ul>
                                        <li><span><b>Fitness Classes & Events:</b> Explore and sign up for fitness classes and wellness events.</span></li>
                                        <li><span><b>Wellness Resources:</b> Access resources and information related to health and well-being.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Green Initiatives</h3>
                                    <ul>
                                        <li><span><b>Sustainability Updates:</b> Stay informed about community-wide sustainability initiatives.</span></li>
                                        <li><span><b>Recycling Programs:</b> Access information on recycling programs and eco-friendly practices.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Survey Participation</h3>
                                    <ul>
                                        <li><span><b>Community Surveys:</b> Participate in surveys to provide input on facility improvements and community preferences.</span></li>
                                        <li><span><b>Opinion Polls:</b> Share opinions on proposed changes or initiatives.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Facility Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Analytics</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> An overview of facility usage, maintenance requests, and community engagement.</span></li>
                                        <li><span><b>Analytics Tools:</b> In-depth analytics on facility utilization, event attendance, and service performance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Facility Management</h3>
                                    <ul>
                                        <li><span><b>Facility Listings:</b> Add, update, and manage details of available facilities and common spaces.</span></li>
                                        <li><span><b>Booking Management:</b> Centralized control over facility bookings, reservations, and availability.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Service Request Management</h3>
                                    <ul>
                                        <li><span><b>Maintenance Requests:</b> Receive, assign, and track maintenance requests efficiently.</span></li>
                                        <li><span><b>Service Response:</b> Monitor response times and completion status of service requests.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Hub</h3>
                                    <ul>
                                        <li><span><b>Announcement Center:</b> Broadcast announcements, alerts, and updates to residents.</span></li>
                                        <li><span><b>Community Forum Moderation:</b> Oversight and moderation of community discussions and forums.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management</h3>
                                    <ul>
                                        <li><span><b>User Profiles:</b> Manage resident profiles, access levels, and communication preferences.</span></li>
                                        <li><span><b>Role-Based Access Control:</b> Granular access control with role-based permissions for different administrative roles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Event Management</h3>
                                    <ul>
                                        <li><span><b>Event Calendar:</b> Create, manage, and promote community events.</span></li>
                                        <li><span><b>RSVP Tracking:</b> Monitor RSVPs and participation levels for events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Document Repository:</b> Centralized storage for facility rules, community guidelines, and important documents.</span></li>
                                        <li><span><b>Version Control:</b> Manage and track document versions for accuracy and compliance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Payments</h3>
                                    <ul>
                                        <li><span><b>Billing System Integration:</b> Integrate with billing systems for tracking facility usage fees and community charges.</span></li>
                                        <li><span><b>Payment Verification:</b> Monitor and verify payments for facility bookings and community services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Service Feedback:</b> Access feedback and ratings for maintenance services and facility experiences.</span></li>
                                        <li><span><b>Community Surveys:</b> Create and analyze community surveys for continuous improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Services Directory</h3>
                                    <ul>
                                        <li><span><b>Services Management:</b> Tools for updating and managing the local services directory for residents.</span></li>
                                        <li><span><b>Recommendation Review:</b> Review and moderate resident recommendations for local services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Management:</b> Oversight of the mobile application, including updates and feature enhancements.</span></li>
                                        <li><span><b>Push Notifications:</b> Ability to send push notifications for important announcements and updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Emergency Response</h3>
                                    <ul>
                                        <li><span><b>Emergency Protocols:</b> Access to emergency contact information, procedures, and tools for communicating urgent information.</span></li>
                                        <li><span><b>Emergency Alerts:</b> A system for sending immediate alerts and notifications during emergencies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Engagement Tools</h3>
                                    <ul>
                                        <li><span><b>Social Calendar:</b> Plan and manage community events, promoting social interactions.</span></li>
                                        <li><span><b>Wellness Programs:</b> Implement and oversee wellness programs and fitness initiatives.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Management</h3>
                                    <ul>
                                        <li><span><b>Vendor Portal:</b> A platform for managing and communicating with vendors providing services to the community.</span></li>
                                        <li><span><b>Performance Evaluation:</b> Evaluate the performance of vendors based on service quality.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Security & Compliance</h3>
                                    <ul>
                                        <li><span><b>Data Encryption:</b> Robust data encryption protocols to ensure the security and privacy of resident information.</span></li>
                                        <li><span><b>Compliance Checks:</b> Tools for monitoring and ensuring compliance with local regulations and community standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Survey Analysis & Reporting</h3>
                                    <ul>
                                        <li><span><b>Survey Results:</b> Analyze survey results to understand resident preferences and feedback.</span></li>
                                        <li><span><b>Reporting Tools:</b> Generate reports on facility usage, event success, and community satisfaction.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration & Customization</h3>
                                    <ul>
                                        <li><span><b>API Integrations:</b> Oversight of third-party integrations and API connections.</span></li>
                                        <li><span><b>Customization Settings:</b> Configuration options for tailoring the system to specific community management needs.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 offset-lg-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>End User Mobile App</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;