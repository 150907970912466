import React from 'react';

const SolutionSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative grey-bg-12 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/home-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover', left: '0' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 offset-lg-7">
                            <div className="capabilities__content">
                                <div className="section__title section__title-4 mb-40">
                                    <h2>Realty Management System is available for:</h2>
                                </div>
                                <div className="capabilities__list">
                                    <ol>
                                        <li>Property Management System</li>
                                        <li>Facility Management System</li>
                                        <li>Property Buy/Sell System</li>
                                        <li>Building/Society Management</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SolutionSection;