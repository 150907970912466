import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {

    return (
        <>
            <div>
                <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <section>
                            <div className="p-0">
                                <div className='side_navBar unClick'>
                                    <div className='about iconAdd'>
                                        <NavLink to="/">Home</NavLink>
                                    </div>
                                    <Collapsible trigger={<NavLink to="/features" className="link">Features</NavLink>} triggerTagName="div"
                                        triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                        <ul className="sidebar_sub_menu submenu">
                                            <li><NavLink to="/features/property-management-system">Property Management System</NavLink></li>
                                            <li><NavLink to="/features/facility-management-system">Facility Management System</NavLink></li>
                                            <li><NavLink to="/features/property-buy-rent-sell-platform">Property Buy/Rent/Sell Platform</NavLink></li>
                                            <li><NavLink to="/features/building-society-management-system">Building/Society Management System</NavLink></li>
                                        </ul>
                                    </Collapsible>
                                    <div className='about iconAdd'>
                                        <li><NavLink to="/about">About Us</NavLink></li>
                                    </div>
                                    <div className='about iconAdd'>
                                        <li><NavLink to="/blog">Blog</NavLink></li>
                                    </div>
                                    <div className='about iconAdd border-0'>
                                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default Sidebar;