import React from 'react';

const PartnerSection = () => {
    return (
        <>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-55">
                                <h2>Our Partners</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mt-10">
                                    <img src="assets/img/partner-logo1.png" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mt-10">
                                    <img src="assets/img/partner-logo2.png" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mt-10">
                                    <img src="assets/img/partner-logo3.png" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-10 mb-lg-0">
                                <div className="services__icon mt-10">
                                    <img src="assets/img/partner-logo4.png" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PartnerSection;