import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative black-bg-2 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/about-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-25">
                                    <h2 className="white-color">We Offers!</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p className="white-color">Sjain Ventures presents an innovative Realty Management Solution that seamlessly integrates advanced tools to streamline the complexities of property management. This comprehensive suite comprises a Property Management System, Facility Management System, Property Buy/Sell/Rent module, and Building or Society Management capabilities, offering a holistic approach to real estate operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <p>The <span>Property Management System</span> serves as the foundation of the solution, providing property owners, managers, and tenants with a centralized platform for efficient property oversight. Covering aspects from property listings and lease management to maintenance requests and financial transactions, this system ensures a smooth and organized management process.</p>
                                    <p>In tandem with this, the <span>Facility Management System</span> enhances the operational efficiency of properties, addressing maintenance schedules, resource allocation, and vendor management. Automation of facility-related tasks enables property owners to optimize costs, reduce downtime, and ensure asset longevity.</p>
                                    <p>The Property Buy/Sell/Rent module introduces a dynamic marketplace within the <span>Realty Management Solution</span>, enabling seamless connections among prospective buyers, sellers, and renters. Users can list their properties and explore available options, simplifying real estate transactions and fostering a vibrant real estate ecosystem.</p>
                                    <p>For community living and shared spaces, the <span>Building or Society Management</span> feature takes the spotlight, offering tools for administrative tasks, event planning, and communication within the community. Residents can access announcements, track shared expenses, and participate in community events, promoting a sense of belonging.</p>
                                    <p className="mb-0">The Sjain Ventures <span>Realty Management Solution</span> distinguishes itself by integrating these modules into a cohesive and user-friendly platform. Leveraging technology, it provides a 360-degree view of property management, meeting the needs of property owners, tenants, buyers, and community members. Whether optimizing property operations, facilitating real estate transactions, or enhancing community living, this solution epitomizes innovation and efficiency in realty management.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;