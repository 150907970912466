import React from 'react';

const ContactSection = () => {
    return (
        <>
            <section className="faq__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <div className="faq__accordion">
                                <div className="accordion" id="accordionExample" >
                                    <div className="card">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    What is a Property Management System, and how does it contribute to effective realty management?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>A Property Management System is a software solution designed to streamline property-related tasks. It aids in tasks such as rent collection, lease management, and maintenance scheduling for efficient realty management.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    How does the Facility Management System enhance property functionality and maintenance?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Facility Management System is designed to optimize the functionality and maintenance of facilities within a property. This includes features for maintenance scheduling, service requests, and overall facility performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    Can you elaborate on the functionalities of the Property Buy/Sell System within the Realty Management platform?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Property Buy/Sell System facilitates buying and selling activities within the platform, providing users with a seamless marketplace for real estate transactions. This includes features for property listings, negotiations, and secure transactions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    What role does the Building/Society Management Platform play in overall realty management?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Building/Society Management Platform is a comprehensive tool for managing buildings and societies. It includes features for communication among residents, maintenance coordination, and administrative tasks for efficient community management.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_5">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="true" aria-controls="collapse_5">
                                                    How does the Property Management System assist in rent collection and lease management?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Property Management System streamlines rent collection processes and effectively manages leases. It often includes features for automated rent payments, lease tracking, and tenant communication.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_6">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="true" aria-controls="collapse_6">
                                                    Can the Facility Management System be customized to suit specific property needs?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_6" className="collapse" aria-labelledby="acc_6" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the Facility Management System is often customizable to meet the specific needs of different properties. Users can tailor features such as maintenance schedules and facility performance tracking to align with their requirements.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_7">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_7" aria-expanded="true" aria-controls="collapse_7">
                                                    What security measures are implemented in the Property Buy/Sell System to ensure safe transactions?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_7" className="collapse" aria-labelledby="acc_7" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Property Buy/Sell System incorporates security protocols to safeguard transactions. This may include secure payment gateways, encryption, and verification processes to ensure the integrity of real estate transactions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_8">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_8" aria-expanded="true" aria-controls="collapse_8">
                                                    How does the Building/Society Management Platform enhance communication among residents within a community?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_8" className="collapse" aria-labelledby="acc_8" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Building/Society Management Platform facilitates communication among residents through features like community forums, announcements, and event coordination. It serves as a centralized hub for community engagement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_9">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_9" aria-expanded="true" aria-controls="collapse_9">
                                                    Can the Property Management System generate reports for property performance and financial analysis?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_9" className="collapse" aria-labelledby="acc_9" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the Property Management System typically offers reporting functionalities, allowing users to generate detailed reports on property performance, financial analysis, and other key metrics for informed decision-making.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_10">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_10" aria-expanded="true" aria-controls="collapse_10">
                                                    How does the Realty Management platform ensure user-friendly interfaces for easy navigation and utilization of its features?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_10" className="collapse" aria-labelledby="acc_10" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Realty Management platform is designed with user-friendly interfaces to ensure easy navigation and utilization of its features. This includes intuitive dashboards, clear categorization of functionalities, and user guides to enhance the overall user experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactSection;