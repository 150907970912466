import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    return (
        <>
            <section className="achievement__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Security Guard Side Features of Building/Society Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Visitor Management</h3>
                                    <ul>
                                        <li><span><b>Visitor Registration:</b> Register visitors entering the premises, capturing details such as name, purpose of visit, and time of arrival.</span></li>
                                        <li><span><b>Gate Pass Printing:</b> Generate and print gate passes for authorized visitors.</span></li>
                                        <li><span><b>Verification Process:</b> Verify the identity of visitors through identification documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Gate Access Control</h3>
                                    <ul>
                                        <li><span><b>Gate Operations:</b> Manage the opening and closing of gates for residents, visitors, and service personnel.</span></li>
                                        <li><span><b>Access Control System Integration:</b> Utilize an integrated access control system for enhanced security.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Response</h3>
                                    <ul>
                                        <li><span><b>Emergency Alert System:</b> Access and activate emergency alert systems in case of security threats or incidents.</span></li>
                                        <li><span><b>Communication with Emergency Services:</b> Communicate with local authorities and emergency services when required.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Patrol Management</h3>
                                    <ul>
                                        <li><span><b>Patrol Scheduling:</b> Plan and schedule regular patrols in and around the premises.</span></li>
                                        <li><span><b>Incident Reporting:</b> Report any security incidents observed during patrols.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Package & Parcel Management</h3>
                                    <ul>
                                        <li><span><b>Package Verification:</b> Verify the identity of delivery personnel and validate packages entering the premises.</span></li>
                                        <li><span><b>Parcel Log:</b> Maintain a log of incoming and outgoing parcels, ensuring accountability.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lost & Found Management</h3>
                                    <ul>
                                        <li><span><b>Lost Item Reporting:</b> Record details of any lost items reported by residents or visitors.</span></li>
                                        <li><span><b>Item Return Process:</b> Facilitate the return of lost items to rightful owners.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Parking Management</h3>
                                    <ul>
                                        <li><span><b>Parking Control:</b> Monitor and control access to parking areas, ensuring compliance with parking regulations.</span></li>
                                        <li><span><b>Parking Violation Reporting:</b> Report and document any violations of parking rules.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Intercom System Integration:</b> Integrate with the intercom system for direct communication with residents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Incident Reporting</h3>
                                    <ul>
                                        <li><span><b>Digital Incident Reports:</b> Submit digital reports for security incidents, detailing the nature of the incident and actions taken.</span></li>
                                        <li><span><b>Time-Stamped Logging:</b> Log incidents with timestamps for accurate record-keeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Rules Enforcement</h3>
                                    <ul>
                                        <li><span><b>Enforce Security Policies:</b> Ensure adherence to community security policies and rules.</span></li>
                                        <li><span><b>Rule Violation Reporting:</b> Report instances of rule violations to appropriate authorities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Evacuation Assistance</h3>
                                    <ul>
                                        <li><span><b>Evacuation Plan Awareness:</b> Be aware of community evacuation plans and procedures.</span></li>
                                        <li><span><b>Assist Residents:</b> Provide assistance and guidance during emergency evacuations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Development</h3>
                                    <ul>
                                        <li><span><b>Security Training Programs:</b> Participate in ongoing training programs to enhance security skills.</span></li>
                                        <li><span><b>Policy Updates:</b> Stay informed about updates to security policies and procedures.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access key features of the building/society management system through a mobile app.</span></li>
                                        <li><span><b>Mobile Incident Reporting:</b> Report incidents in real-time using a mobile device.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Building Management System</h3>
                                    <ul>
                                        <li><span><b>Integration with BMS:</b> Collaborate with the building management system for seamless coordination.</span></li>
                                        <li><span><b>Alerts & Notifications:</b> Receive instant alerts and notifications related to security issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Security & Privacy</h3>
                                    <ul>
                                        <li><span><b>Data Confidentiality:</b> Adhere to strict confidentiality standards when handling security-related information.</span></li>
                                        <li><span><b>Secure Communication Channels:</b> Utilize secure communication channels to prevent data breaches.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 offset-lg-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for Security Guard</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;