import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User</button>
                                <button onClick={() => scrollTo("accountant")}>Accountant</button>
                                <button onClick={() => scrollTo("manager")}>Property Manager</button>
                                <button onClick={() => scrollTo("contractor")}>Contractor/Vendor</button>
                                <button onClick={() => scrollTo("insurance")}>Insurance Company</button>
                                <button onClick={() => scrollTo("admin")}>Admin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Dashboard</h3>
                                    <ul>
                                        <li><span><b>Property Overview:</b> A centralized dashboard providing an overview of the user's properties, including occupancy status, upcoming events, and maintenance alerts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lease Management</h3>
                                    <ul>
                                        <li><span><b>Lease Details:</b> Access to comprehensive information about existing leases, including lease terms, renewal dates, and rental payment history.</span></li>
                                        <li><span><b>Rent Payment Portal:</b> A secure portal for making rent payments online, with options for scheduled payments and digital receipts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Requests</h3>
                                    <ul>
                                        <li><span><b>Submission & Tracking:</b> An intuitive interface for submitting maintenance requests, with real-time tracking and status updates.</span></li>
                                        <li><span><b>Communication with Maintenance Team:</b> Direct communication with the maintenance team for updates and additional information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Transactions</h3>
                                    <ul>
                                        <li><span><b>Transaction History:</b> Access to a detailed transaction history, including rent payments, security deposits, and any associated fees.</span></li>
                                        <li><span><b>Financial Reports:</b> Generated reports for a clear understanding of financial activities related to the property.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Communication</h3>
                                    <ul>
                                        <li><span><b>Message Center:</b> A centralized communication hub for receiving announcements, alerts, and important messages from property management.</span></li>
                                        <li><span><b>Community Forum:</b> A space for residents to engage in discussions, share information, and build a sense of community.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Repository</h3>
                                    <ul>
                                        <li><span><b>Lease Documents:</b> Access to lease agreements and other important documents related to the property.</span></li>
                                        <li><span><b>Community Rules & Guidelines:</b> Instant access to community rules and guidelines for easy reference.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App:</b> A user-friendly mobile application for on-the-go access to property-related information, maintenance requests, and community updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Event Participation</h3>
                                    <ul>
                                        <li><span><b>Event Calendar:</b> Visibility into upcoming community events and activities.</span></li>
                                        <li><span><b>RSVP & Participation:</b> Ability to RSVP for events and participate in community gatherings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Profile Management</h3>
                                    <ul>
                                        <li><span><b>Personal Information:</b> Manage personal details, contact information, and communication preferences.</span></li>
                                        <li><span><b>Privacy Settings:</b> Control privacy settings for personal information within the community.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Assistance</h3>
                                    <ul>
                                        <li><span><b>Emergency Contacts:</b> Easily accessible emergency contact information and procedures.</span></li>
                                        <li><span><b>Emergency Alerts:</b> Receive immediate alerts and instructions during emergency situations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Maintenance Feedback:</b> Provide feedback on completed maintenance requests.</span></li>
                                        <li><span><b>Community Ratings:</b> Participate in community surveys and rate community services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Educational Resources</h3>
                                    <ul>
                                        <li><span><b>Guides & FAQs:</b> Access to guides, FAQs, and educational resources on using the property management system effectively.</span></li>
                                        <li><span><b>Tips and Reminders:</b> Regular tips and reminders to optimize the user experience and enhance property living.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Services Directory</h3>
                                    <ul>
                                        <li><span><b>Local Services:</b> Information on local services, amenities, and businesses in the vicinity.</span></li>
                                        <li><span><b>Recommendations:</b> A platform for residents to share and discover recommendations for local services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Social Connectivity</h3>
                                    <ul>
                                        <li><span><b>Resident Directory:</b> Access to a directory of fellow residents for social connectivity.</span></li>
                                        <li><span><b>Messaging & Chat:</b> Direct messaging and chat features for communication with neighbors.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="accountant">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Accountant Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Reporting</h3>
                                    <ul>
                                        <li><span><b>Income Statements:</b> Generate detailed income statements that include rent revenue, fees, and other income sources.</span></li>
                                        <li><span><b>Expense Reports:</b> Access comprehensive reports outlining property-related expenses, maintenance costs, and other financial outflows.</span></li>
                                        <li><span><b>Profit & Loss Statements:</b> Evaluate the financial performance of properties with profit and loss statements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Rent Collection & Invoicing</h3>
                                    <ul>
                                        <li><span><b>Rent Invoicing:</b> Create and send rent invoices to tenants, specifying due dates and payment details.</span></li>
                                        <li><span><b>Late Fee Management:</b> Automate the calculation and application of late fees for overdue rent payments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Expense Management</h3>
                                    <ul>
                                        <li><span><b>Expense Tracking:</b> Log and categorize property-related expenses, including maintenance, repairs, and operational costs.</span></li>
                                        <li><span><b>Approval Workflows:</b> Implement approval workflows for expenses, ensuring proper authorization before payment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Budgeting Tools</h3>
                                    <ul>
                                        <li><span><b>Budget Creation:</b> Develop and manage property budgets, allocating funds for various expenses and contingencies.</span></li>
                                        <li><span><b>Budget vs. Actual Analysis:</b> Compare actual expenses against the budget to identify variances and make informed financial decisions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Forecasting</h3>
                                    <ul>
                                        <li><span><b>Revenue Forecasting:</b> Utilize tools for forecasting future revenue based on historical data and market trends.</span></li>
                                        <li><span><b>Expense Projections:</b> Project future expenses to aid in long-term financial planning.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Payment Management</h3>
                                    <ul>
                                        <li><span><b>Invoice Processing:</b> Receive and process invoices from vendors providing services to the properties.</span></li>
                                        <li><span><b>Payment Scheduling:</b> Schedule payments to vendors, ensuring timely and accurate disbursements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Bank Reconciliation</h3>
                                    <ul>
                                        <li><span><b>Bank Statement Integration:</b> Integrate with bank statements for automated reconciliation of financial transactions.</span></li>
                                        <li><span><b>Transaction Matching:</b> Match system records with bank transactions to identify discrepancies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Tax Management</h3>
                                    <ul>
                                        <li><span><b>Tax Record Keeping:</b> Maintain organized records for tax purposes, including relevant documentation and transaction history.</span></li>
                                        <li><span><b>Tax Compliance Checks:</b> Implement tools to ensure compliance with tax regulations and reporting requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Audits</h3>
                                    <ul>
                                        <li><span><b>Audit Trail Access:</b> Review detailed audit trails of financial transactions and system activities.</span></li>
                                        <li><span><b>Audit Preparation:</b> Facilitate the preparation and coordination of financial audits as needed.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Security & Compliance</h3>
                                    <ul>
                                        <li><span><b>Data Encryption:</b> Ensure the encryption of sensitive financial information to maintain data security.</span></li>
                                        <li><span><b>Compliance Checks:</b> Monitor and enforce compliance with financial regulations and standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Collaboration with Property Managers</h3>
                                    <ul>
                                        <li><span><b>Communication Tools:</b> Facilitate communication with property managers regarding financial matters and budgetary requirements.</span></li>
                                        <li><span><b>Collaborative Financial Planning:</b> Work collaboratively with property managers to align financial strategies with property goals.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Accounting Software</h3>
                                    <ul>
                                        <li><span><b>Software Compatibility:</b> Integrate seamlessly with accounting software for streamlined financial management.</span></li>
                                        <li><span><b>Automated Bookkeeping:</b> Automate the recording of financial transactions to enhance efficiency.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Forecasting & Analysis Tools</h3>
                                    <ul>
                                        <li><span><b>Market Analysis:</b> Utilize tools for analyzing market trends and making informed financial decisions.</span></li>
                                        <li><span><b>Portfolio Analysis:</b> Evaluate the financial performance of the entire property portfolio.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Automation of Recurring Transactions</h3>
                                    <ul>
                                        <li><span><b>Automated Rent Collection:</b> Set up automated processes for recurring rent collection, reducing manual workload.</span></li>
                                        <li><span><b>Scheduled Payments:</b> Schedule recurring payments for regular expenses, such as utilities and maintenance contracts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Support for Decision-Making</h3>
                                    <ul>
                                        <li><span><b>Financial Consultation:</b> Provide financial insights and support to property managers and stakeholders for strategic decision-making.</span></li>
                                        <li><span><b>Scenario Analysis:</b> Conduct scenario analyses to assess the financial impact of various decisions and market changes.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="manager">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Property Manager Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Overview</h3>
                                    <ul>
                                        <li><span><b>Centralized Dashboard:</b> A comprehensive dashboard providing a quick overview of all managed properties, including occupancy rates, pending maintenance, and financial summaries.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lease & Tenant Management</h3>
                                    <ul>
                                        <li><span><b>Lease Administration:</b> Tools for creating, editing, and managing lease agreements, including lease terms, rent amounts, and renewal options.</span></li>
                                        <li><span><b>Tenant Profiles:</b> Access to detailed tenant profiles, including contact information, lease history, and communication logs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Rent Collection & Billing</h3>
                                    <ul>
                                        <li><span><b>Rent Collection Dashboard:</b> Monitor rent collection statuses, track payments, and manage billing cycles.</span></li>
                                        <li><span><b>Rent Adjustment Tools:</b> Ability to adjust rents, apply discounts, and manage late fees as needed.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Management</h3>
                                    <ul>
                                        <li><span><b>Maintenance Request Handling:</b> Receive, assign, and manage maintenance requests efficiently.</span></li>
                                        <li><span><b>Preventive Maintenance Scheduling:</b> Schedule and track preventive maintenance tasks to ensure property upkeep.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Insights</h3>
                                    <ul>
                                        <li><span><b>Financial Reports:</b> Access to financial reports, including income statements, expense breakdowns, and profit/loss summaries.</span></li>
                                        <li><span><b>Budget Tracking:</b> Monitor budget adherence and receive alerts for potential budget overruns.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Hub</h3>
                                    <ul>
                                        <li><span><b>Announcements & Alerts:</b> A platform for sending announcements, alerts, and updates to tenants and property owners.</span></li>
                                        <li><span><b>Tenant Communication:</b> Direct communication with tenants regarding lease-related matters, maintenance updates, and community news.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Document Repository:</b> A centralized storage space for important documents, including lease agreements, property rules, and regulatory documents.</span></li>
                                        <li><span><b>Document Sharing:</b> Share documents with tenants and property owners securely.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Tenant Relations</h3>
                                    <ul>
                                        <li><span><b>Tenant Portals:</b> Provide tenants with online portals for accessing lease documents, making maintenance requests, and viewing payment history.</span></li>
                                        <li><span><b>Tenant Engagement Tools:</b> Tools for fostering positive tenant relationships, including feedback mechanisms and community engagement features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vacancy Management</h3>
                                    <ul>
                                        <li><span><b>Listing Vacant Units:</b> Tools for listing and marketing vacant units.</span></li>
                                        <li><span><b>Applicant Management:</b> Manage tenant applications, conduct background checks, and streamline the leasing process.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Task & Work Order Tracking</h3>
                                    <ul>
                                        <li><span><b>Task Lists:</b> Create and manage task lists for property-related activities.</span></li>
                                        <li><span><b>Work Order Status:</b> Track the status of work orders and maintenance tasks in real-time.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Occupancy Reports:</b> Analyze occupancy rates and trends for strategic decision-making.</span></li>
                                        <li><span><b>Performance Metrics:</b> Utilize performance metrics to assess property performance and identify areas for improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor & Contractor Management</h3>
                                    <ul>
                                        <li><span><b>Vendor Directory:</b> Maintain a directory of vendors and contractors for property maintenance and repairs.</span></li>
                                        <li><span><b>Performance Evaluation:</b> Evaluate vendor performance and reliability.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Access Control</h3>
                                    <ul>
                                        <li><span><b>Access to Sensitive Information:</b> Controlled access to sensitive tenant and financial information based on role permissions.</span></li>
                                        <li><span><b>Data Security Features:</b> Ensure the security of tenant data and financial information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Event Planning</h3>
                                    <ul>
                                        <li><span><b>Event Calendar:</b> Plan and organize community events, managing RSVPs and participation.</span></li>
                                        <li><span><b>Event Communication:</b> Communicate event details and updates to residents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access to property management tools on a mobile app for on-the-go management.</span></li>
                                        <li><span><b>Push Notifications:</b> Receive push notifications for critical updates and alerts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compliance Management</h3>
                                    <ul>
                                        <li><span><b>Regulatory Compliance Checks:</b> Tools to ensure adherence to local regulations and property management standards.</span></li>
                                        <li><span><b>Documentation for Audits:</b> Prepare and maintain documentation for financial and regulatory audits.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Tenant Onboarding & Offboarding</h3>
                                    <ul>
                                        <li><span><b>Onboarding Tools:</b> Streamlined processes for onboarding new tenants, including lease signing and orientation.</span></li>
                                        <li><span><b>Offboarding Process:</b> Efficient handling of lease terminations and tenant departures.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Accounting Software</h3>
                                    <ul>
                                        <li><span><b>Seamless Accounting Integration:</b> Integration with accounting software for streamlined financial management.</span></li>
                                        <li><span><b>Automated Record Keeping:</b> Automation of financial transactions for accurate record-keeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support</h3>
                                    <ul>
                                        <li><span><b>Training Resources:</b> Access to training materials for effectively using the property management system.</span></li>
                                        <li><span><b>Customer Support:</b> Access to customer support for assistance with system-related queries.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="contractor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Contractor or Vendor Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Dashboard</h3>
                                    <ul>
                                        <li><span><b>Centralized Dashboard:</b> An intuitive dashboard providing an overview of assigned tasks, work orders, and upcoming projects.</span></li>
                                        <li><span><b>Notification Center:</b> Receive real-time notifications for new assignments, updates, and communication from property managers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Task & Work Order Management</h3>
                                    <ul>
                                        <li><span><b>Task Assignment:</b> Receive assigned tasks and work orders directly from property managers.</span></li>
                                        <li><span><b>Work Order Details:</b> Access detailed information about the scope of work, deadlines, and any specific requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> A built-in messaging system for direct communication with property managers.</span></li>
                                        <li><span><b>Document Sharing:</b> Ability to share important documents, such as work contracts and specifications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Service Acceptance</h3>
                                    <ul>
                                        <li><span><b>Acceptance Confirmation:</b> Confirm acceptance of assigned tasks or work orders.</span></li>
                                        <li><span><b>Decline Option:</b> Provide reasons for declining tasks along with the option to suggest alternatives.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access the property management system through a mobile app for on-the-go updates.</span></li>
                                        <li><span><b>Mobile Task Management:</b> View and manage assigned tasks directly from a mobile device.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Invoice Submission</h3>
                                    <ul>
                                        <li><span><b>Digital Invoicing:</b> Submit digital invoices for completed tasks or services rendered.</span></li>
                                        <li><span><b>Invoice Details:</b> Include detailed information such as labor hours, materials used, and any additional charges.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Document Submission:</b> Upload relevant documents, certifications, and licenses required for compliance.</span></li>
                                        <li><span><b>Document Verification:</b> Property managers can review and verify submitted documents for compliance checks.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Performance Tracking</h3>
                                    <ul>
                                        <li><span><b>Task Completion Status:</b> Track the status of assigned tasks and work orders.</span></li>
                                        <li><span><b>Performance Metrics:</b> Access performance metrics and feedback from property managers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling</h3>
                                    <ul>
                                        <li><span><b>Calendar Integration:</b> View and manage appointments and scheduled tasks on an integrated calendar.</span></li>
                                        <li><span><b>Appointment Confirmation:</b> Confirm attendance for scheduled appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Feedback Submission:</b> Submit feedback and updates on the completion of tasks.</span></li>
                                        <li><span><b>Ratings System:</b> Property managers can provide ratings and reviews based on the quality of services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Expense Management</h3>
                                    <ul>
                                        <li><span><b>Expense Submission:</b> Submit expense reports for materials, equipment, or additional costs incurred.</span></li>
                                        <li><span><b>Approval Workflow:</b> Property managers can review and approve submitted expenses.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Directory</h3>
                                    <ul>
                                        <li><span><b>Profile Management:</b> Manage vendor profiles, including contact information, service offerings, and performance history.</span></li>
                                        <li><span><b>Visibility to Property Managers:</b> Allow property managers to access vendor profiles for task assignment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Accounting Software</h3>
                                    <ul>
                                        <li><span><b>Billing Integration:</b> Seamless integration with accounting software for efficient billing and payment processes.</span></li>
                                        <li><span><b>Automated Record Keeping:</b> Automation of financial transactions for accurate record-keeping.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support</h3>
                                    <ul>
                                        <li><span><b>Training Resources:</b> Access to training materials for using the property management system effectively.</span></li>
                                        <li><span><b>Customer Support:</b> Direct access to customer support for system-related queries.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compliance Checks</h3>
                                    <ul>
                                        <li><span><b>Regulatory Compliance:</b> Ensure compliance with industry regulations and standards.</span></li>
                                        <li><span><b>Document Validation:</b> Automated checks for valid licenses, certifications, and insurance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Reminders</h3>
                                    <ul>
                                        <li><span><b>Automated Reminders:</b> Receive automated reminders for upcoming appointments and deadlines.</span></li>
                                        <li><span><b>Schedule Coordination:</b> Coordinate schedules with property managers for optimal task management.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Insurance Verification</h3>
                                    <ul>
                                        <li><span><b>Insurance Documentation:</b> Submit and update insurance documentation for property managers to verify.</span></li>
                                        <li><span><b>Automated Expiry Alerts:</b> Receive automated alerts for insurance policy expiry dates.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="insurance">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Insurance Company Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Policy Management</h3>
                                    <ul>
                                        <li><span><b>Policy Submissions:</b> Submit and manage insurance policies for properties within the management system.</span></li>
                                        <li><span><b>Policy Details:</b> Access detailed information about insured properties, coverage limits, and policy terms.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Claims Management</h3>
                                    <ul>
                                        <li><span><b>Claims Submission:</b> Property managers can submit insurance claims directly through the system.</span></li>
                                        <li><span><b>Claim Status Tracking:</b> Track the status of submitted claims in real-time.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Document Upload:</b> Upload and manage policy documents, certificates of insurance, and other relevant files.</span></li>
                                        <li><span><b>Document Verification:</b> Property managers can review and verify submitted insurance documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> A built-in messaging system for direct communication with property managers.</span></li>
                                        <li><span><b>Claim Updates:</b> Receive real-time updates on the status of insurance claims.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Performance Analytics</h3>
                                    <ul>
                                        <li><span><b>Claim Performance Metrics:</b> Access analytics on claim processing times, settlements, and resolution rates.</span></li>
                                        <li><span><b>Risk Assessment Tools:</b> Utilize risk assessment tools to evaluate property risks.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Automated Notifications</h3>
                                    <ul>
                                        <li><span><b>Renewal Reminders:</b> Receive automated reminders for policy renewals and expirations.</span></li>
                                        <li><span><b>Claim Status Alerts:</b> Automated notifications for changes in claim status.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Property Management System</h3>
                                    <ul>
                                        <li><span><b>Data Synchronization:</b> Seamless integration with property management systems for accurate data exchange.</span></li>
                                        <li><span><b>Automated Reporting:</b> Generate automated reports on insured properties, claims history, and policy details.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Policy Renewals & Adjustments</h3>
                                    <ul>
                                        <li><span><b>Renewal Processes:</b> Initiate and manage policy renewals directly within the system.</span></li>
                                        <li><span><b>Adjustment Requests:</b> Property managers can request adjustments to coverage limits or policy terms.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Payments</h3>
                                    <ul>
                                        <li><span><b>Billing Integration:</b> Integration with property management system for efficient billing and payment processes.</span></li>
                                        <li><span><b>Payment Verification:</b> Verify premium payments and ensure timely processing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support</h3>
                                    <ul>
                                        <li><span><b>Help Desk Access:</b> Access to a dedicated help desk for addressing queries and providing support.</span></li>
                                        <li><span><b>Assistance with Claims:</b> Provide support to property managers during the claims process.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Risk Mitigation Tools</h3>
                                    <ul>
                                        <li><span><b>Risk Assessment Reports:</b> Generate reports on property risks and recommendations for risk mitigation.</span></li>
                                        <li><span><b>Loss Prevention Resources:</b> Provide resources and guidelines to property managers for loss prevention.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compliance Checks</h3>
                                    <ul>
                                        <li><span><b>Regulatory Compliance:</b> Ensure compliance with insurance regulations and standards.</span></li>
                                        <li><span><b>Document Validation:</b> Automated checks for valid policies and compliance documentation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Policy Performance Reports:</b> Analyze the performance of insurance policies over time.</span></li>
                                        <li><span><b>Claim Trends Analysis:</b> Identify trends in claim submissions and resolutions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access the insurance management system through a mobile app for on-the-go updates.</span></li>
                                        <li><span><b>Mobile Claim Submission:</b> Property managers can submit claims and communicate with insurance companies through the app.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Security & Privacy</h3>
                                    <ul>
                                        <li><span><b>Data Encryption:</b> Implement robust data encryption protocols to protect sensitive insurance and property information.</span></li>
                                        <li><span><b>User Authentication:</b> Ensure secure user authentication for authorized access.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Collaboration with Property Managers</h3>
                                    <ul>
                                        <li><span><b>Communication Hub:</b> Facilitate direct communication with property managers for information exchange.</span></li>
                                        <li><span><b>Collaborative Claims Resolution:</b> Work collaboratively with property managers to expedite claims resolution.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Property Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Analytics</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> A centralized dashboard providing an overview of all properties, occupancy rates, financial metrics, and maintenance statuses.</span></li>
                                        <li><span><b>Real-time Analytics:</b> In-depth analytics on property performance, vacancy rates, and financial trends.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Property Portfolio Management</h3>
                                    <ul>
                                        <li><span><b>Property Listings:</b> Tools for adding, updating, and managing property listings.</span></li>
                                        <li><span><b>Portfolio Overview:</b> A comprehensive view of the entire property portfolio, including detailed information on each property.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lease Management</h3>
                                    <ul>
                                        <li><span><b>Lease Agreements:</b> Access to all lease agreements, with the ability to upload, edit, and renew leases.</span></li>
                                        <li><span><b>Tenant Profiles:</b> Centralized tenant profiles, including contact details, rental history, and communication logs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Rent & Financial Management</h3>
                                    <ul>
                                        <li><span><b>Rent Collection:</b> Streamlined tools for collecting rent payments, generating invoices, and tracking payment statuses.</span></li>
                                        <li><span><b>Financial Reports:</b> Detailed financial reports, including income statements, expense breakdowns, and profit/loss summaries.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance & Work Order Management</h3>
                                    <ul>
                                        <li><span><b>Maintenance Requests:</b> A centralized system for managing maintenance requests, assigning tasks to maintenance teams, and tracking progress.</span></li>
                                        <li><span><b>Preventive Maintenance Scheduler:</b> Tools for scheduling and managing preventive maintenance tasks to optimize property upkeep.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Hub</h3>
                                    <ul>
                                        <li><span><b>Announcement Center:</b> A communication hub for sending announcements, alerts, and updates to all residents.</span></li>
                                        <li><span><b>Community Forum Moderation:</b> Oversight and moderation tools for managing community discussions and forums.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Document Repository:</b> Centralized storage for lease agreements, community rules, and other important documents.</span></li>
                                        <li><span><b>Version Control:</b> Manage and track document versions for accuracy and compliance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management</h3>
                                    <ul>
                                        <li><span><b>Role-Based Access Control:</b> Granular access control with role-based permissions for different administrative roles.</span></li>
                                        <li><span><b>User Activity Logs:</b> Detailed logs of user activities within the property management system.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Event Management</h3>
                                    <ul>
                                        <li><span><b>Event Calendar:</b> Tools for creating and managing community events, including RSVP tracking.</span></li>
                                        <li><span><b>Event Attendance:</b> Monitor attendance and participation in community events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Response</h3>
                                    <ul>
                                        <li><span><b>Emergency Protocols:</b> Access to emergency contact information, procedures, and tools for communicating urgent information to residents.</span></li>
                                        <li><span><b>Emergency Alerts:</b> A system for sending immediate alerts and notifications during emergencies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Surveys</h3>
                                    <ul>
                                        <li><span><b>Maintenance Feedback:</b> Tools for reviewing and responding to maintenance feedback from residents.</span></li>
                                        <li><span><b>Community Surveys:</b> Create and analyze community surveys to gather feedback and insights.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Management:</b> Oversight of the mobile application, including updates and feature enhancements.</span></li>
                                        <li><span><b>Push Notifications:</b> Ability to send push notifications to residents for important announcements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Services Directory</h3>
                                    <ul>
                                        <li><span><b>Services Management:</b> Tools for updating and managing the local services directory for residents.</span></li>
                                        <li><span><b>Recommendation Review:</b> Review and moderate resident recommendations for local services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Planning</h3>
                                    <ul>
                                        <li><span><b>Budgeting Tools:</b> Budget creation and management tools for financial planning.</span></li>
                                        <li><span><b>Expense Approval:</b> Oversight of community expenses, with the ability to approve or reject expense requests.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Security & Compliance</h3>
                                    <ul>
                                        <li><span><b>Data Encryption:</b> Robust data encryption protocols to ensure the security and privacy of sensitive information.</span></li>
                                        <li><span><b>Compliance Checks:</b> Tools for monitoring and ensuring compliance with local regulations and property management standards.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vendor Management</h3>
                                    <ul>
                                        <li><span><b>Vendor Portal:</b> A platform for managing and communicating with vendors providing services to the properties.</span></li>
                                        <li><span><b>Performance Evaluation:</b> Tools for evaluating the performance of vendors based on service quality.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Support & Training</h3>
                                    <ul>
                                        <li><span><b>Help Desk Management:</b> Oversight of the help desk and support ticket system for addressing user queries and issues.</span></li>
                                        <li><span><b>Training Resources:</b> Creation and management of training resources for users and staff.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration & Customization</h3>
                                    <ul>
                                        <li><span><b>API Integrations:</b> Oversight of third-party integrations and API connections.</span></li>
                                        <li><span><b>Customization Settings:</b> Configuration options for tailoring the system to specific property management needs.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="services__item mb-15 mb-lg-4">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Accountant</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__item mb-15 mb-lg-4">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Contractor</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__item mb-15 mb-lg-4">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__item mb-15 mb-lg-4">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Tenant</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Property Manager</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Insurance Company</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for End User/Tenant</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;